import React from 'react';

const TwoDoorCinemaClub = props => (
    <div>
        <iframe name="two_door_cinema_club" width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1847532015&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"/>
    </div>
);

export default TwoDoorCinemaClub;
